// ======================================================
// Message
// ======================================================

:root {
  --message-accent: transparent;
  --message-color: #{$palette-base-800};
  --message-border-accent: #{$palette-base-500};
  --message-border-width: 0;
  --message-padding: 0;
}

.c-message {
  background: var(--message-accent) none;
  color: var(--message-color);
  border: var(--message-border-width) solid var(--message-border-accent);
  padding: var(--message-padding);
  border-radius: 0.2em;

  // Modifiers
  // -------------------------------------------

  &.is-info,
  &--info {
    --message-color: #{$palette-info-900};
    --message-border-accent: #{$palette-info-400};
  }

  &.is-warning,
  &--warning {
    --message-color: #{$palette-warning-900};
    --message-border-accent: #{$palette-warning-400};
  }

  &.is-error,
  &--error {
    --message-color: #{$palette-error-500};
    --message-border-accent: #{$palette-error-400};
  }

  &.is-success,
  &--success {
    --message-color: #{$palette-success-900};
    --message-border-accent: #{$palette-success-400};
  }
}
