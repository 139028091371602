// ======================================================
// Icon
// ======================================================

:root {
  --icon-width: #{px-to-rem(20)};
  --icon-height: #{px-to-rem(20)};
}

.c-icon {
  width: var(--icon-width);
  height: var(--icon-height);
}
