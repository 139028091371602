// ======================================================
// Form Group
// ======================================================

.l-form-group {
  $this: &;

  & + & {
    margin-top: px-to-rem(10);
  }

  // Children
  // -------------------------------------------

  &__header {
    + #{$this}__content {
      margin-top: px-to-rem(5);
    }
  }

  &__content {
    margin-top: 0;
  }

  &__footer {
    margin-top: 0;
  }
}
