// ======================================================
// Text Button
// ======================================================

:root {
  --text-button-color: #{$palette-primary-500};
  --text-button-font-size: #{px-to-rem(12)};
  --text-button-content-border-width: #{px-to-rem(1)};

  @include media-breakpoint-up(md) {
    --text-button-color: #{$palette-base-900};
    --text-button-font-size: #{px-to-rem(20)};
    --text-button-content-border-width: #{px-to-rem(2)};
  }
}

.c-text-button {
  @include strip-button-style;

  cursor: pointer;
  color: var(--text-button-color);
  position: relative;
  font-size: var(--text-button-font-size);
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  @include media-breakpoint-up(md) {
    width: auto;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  // Modifiers
  // -------------------------------------------

  &--base {
    --text-button-color: #{$palette-base-900};
  }

  &--large {
    --text-button-font-size: #{px-to-rem(14)};

    @include media-breakpoint-up(md) {
      --text-button-font-size: #{px-to-rem(18)};
    }
  }

  &--color-white {
    color: $palette-base-100;
  }

  // States
  // -------------------------------------------

  &.is-disabled {
    //
  }

  // Children
  // -------------------------------------------

  &__content {
    border-bottom: var(--text-button-content-border-width) solid currentColor;
    padding-bottom: 0.1em;
  }
}
