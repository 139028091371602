// ======================================================
// Checkout Page
// ======================================================

:root {
  --checkout-page-accent: #{$palette-base-100};
  --checkout-page-color: #{$palette-base-900};
  --checkout-page-content-padding: #{px-to-rem(40)} #{px-to-rem(25)} #{px-to-rem(40)};
}

.l-checkout-page {
  background: var(--checkout-page-accent);
  color: var(--checkout-page-color);
  position: relative;
  margin-top: 0;

  // Children
  // -------------------------------------------

  &__indicator {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: $z-index-fixed;
  }

  &__header {
    margin: 0;
    background: $palette-brand-secondary;
    color: $palette-base-900;
    position: relative;
    padding: px-to-rem(32) px-to-rem(44) 0;
    text-align: center;

    &::before {
      content: '';
      display: block;
      height: px-to-rem(50);
      width: 100%;
      background: var(--checkout-page-accent);
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
    }
  }

  &__header-inner {
    position: relative;
    max-width: px-to-rem(505);
    margin: 0 auto;
  }

  &__content {
    max-width: px-to-rem(615);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: var(--checkout-page-content-padding);
  }
}
