// ======================================================
// Button
// ======================================================

:root {
  --button-accent: #{$palette-base-800};
  --button-color: #{$palette-base-100};
  --button-border-accent: #{$palette-base-800};
  --button-border-width: #{px-to-rem(2)};
  --button-border-radius: #{px-to-rem(25)};
  --button-focus-accent: #{$palette-base-800};
  --button-padding: 0 #{px-to-rem(25)};
  --button-height: #{px-to-rem(44)};
  --button-font-size: #{px-to-rem(14)};
  --button-icon-size: 0.5em;
  --button-icon-gutter-x: 0.5em;
}

.c-button {
  --icon-size: var(--button-icon-size);

  $this: &;

  @include strip-button-style;
  @include transition;

  font-family: $font-family-bold;
  background: var(--button-accent) none;
  color: var(--button-color);
  border: var(--button-border-width) solid var(--button-border-accent);
  border-radius: var(--button-border-radius);
  padding: var(--button-padding);
  height: var(--button-height);
  font-size: var(--button-font-size);
  font-weight: 500;
  position: relative;
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  touch-action: manipulation;
  transition-property: color, background-color, border-color;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  @include media-breakpoint-up(md) {
    width: auto;
  }

  &:focus {
    outline: none;
    --button-accent: #{$palette-base-800};
    --button-border-accent: #{$palette-base-800};
  }

  &.is-current,
  &.is-active,
  &:hover {
    --button-accent: #{$palette-base-700};
    --button-border-accent: #{$palette-base-700};
  }

  // States
  // -------------------------------------------

  &.is-disabled {
    cursor: not-allowed;
  }

  // Modifiers
  // -------------------------------------------

  &--small {
    --button-padding: 0 #{px-to-rem(10)};
    --button-height: #{px-to-rem(28)};
    --button-border-radius: #{px-to-rem(14)};
    --button-font-size: #{px-to-rem(14)};
    --button-icon-gutter-x: #{px-to-rem(5)};

    @include media-breakpoint-up(md) {
      --button-border-width: #{px-to-rem(3.75)};
      --button-padding: 0 #{px-to-rem(30)};
      --button-height: #{px-to-rem(54)};
      --button-border-radius: #{px-to-rem(27)};
      --button-font-size: #{px-to-rem(26)};
    }
  }

  &--large {
    --button-height: #{px-to-rem(50)};
    --button-font-size: #{px-to-rem(19)};

    @include media-breakpoint-up(md) {
      --button-border-width: #{px-to-rem(3.75)};
      --button-padding: 0 #{px-to-rem(44)};
      --button-height: #{px-to-rem(72)};
      --button-border-radius: #{px-to-rem(36)};
      --button-font-size: #{px-to-rem(36)};
    }
  }

  &--wide {
    --button-height: #{px-to-rem(52)};
    --button-border-radius: #{px-to-rem(26)};
    --button-font-size: #{px-to-rem(19)};
    --button-padding: 0 #{px-to-rem(55)};

    @include media-breakpoint-up(md) {
      --button-padding: 0 #{px-to-rem(100)};
      --button-height: #{px-to-rem(70)};
      --button-border-radius: #{px-to-rem(35)};
      --button-font-size: #{px-to-rem(28)};
    }

    @include media-breakpoint-up(xxxl) {
      --button-padding: 0 #{px-to-rem(100)};
      --button-height: #{px-to-rem(96)};
      --button-border-radius: #{px-to-rem(48)};
    }
  }

  &--no-border {
    border: 0;
  }

  &--primary {
    --button-accent: #{$palette-primary-500};
    --button-color: #{$palette-primary-100};
    --button-border-accent: #{$palette-primary-500};
    --button-focus-accent: #{$palette-primary-300};

    &.is-current,
    &.is-active,
    &:focus,
    &:hover {
      --button-accent: #{$palette-primary-500};
      --button-border-accent: #{$palette-primary-500};
    }

    &.is-bordered {
      --button-accent: transparent;
      --button-color: #{$palette-primary-500};

      &.is-current,
      &.is-active,
      &:focus,
      &:hover {
        --button-accent: transparent;
      }
    }
  }

  &--primary-alt {
    --button-accent: #{$palette-primary-100};
    --button-color: #{$palette-base-800};
    --button-border-accent: #{$palette-primary-100};
    --button-focus-accent: #{$palette-primary-300};

    &.is-current,
    &.is-active,
    &:focus,
    &:hover {
      --button-accent: #{$palette-primary-100};
      --button-color: #{$palette-base-800};
    }

    @include media-breakpoint-down(md) {
      &:focus,
      &:hover {
        --button-accent: #{$palette-primary-100};
        --button-color: #{$palette-base-800};
      }
    }

    &.is-bordered {
      --button-border-accent: #{$palette-base-800};

      &.is-current,
      &.is-active,
      &:focus,
      &:hover {
        --button-accent: #{$palette-base-800};
        --button-color: #{$palette-primary-100};
      }

      @include media-breakpoint-down(md) {
        &:focus,
        &:hover {
          --button-accent: #{$palette-primary-100};
          --button-color: #{$palette-base-800};
        }
      }
    }

    &.is-disabled {
      //
    }
  }

  &--secondary {
    --button-accent: #{$palette-base-100};
    --button-color: #{$palette-primary-500};
    --button-border-accent: #{$palette-base-100};
    --button-focus-accent: #{$palette-secondary-300};

    &.is-current,
    &.is-active,
    &:focus,
    &:hover {
      --button-accent: #{$palette-primary-500};
      --button-border-accent: #{$palette-base-100};
      --button-color: #{$palette-base-100};
    }

    &.is-bordered {
      --button-accent: transparent;
      --button-color: #{$palette-primary-500};

      &.is-current,
      &.is-active,
      &:focus,
      &:hover {
        --button-accent: transparent;
      }
    }
  }

  &--tertiary {
    --button-accent: transparent;
    --button-color: #{$palette-base-800};
    --button-border-accent: #{$palette-primary-100};
    --button-focus-accent: #{$palette-primary-300};

    &.is-current,
    &.is-active,
    &:hover {
      --button-accent: transparent;
    }

    @include media-breakpoint-down(md) {
      &:hover {
        --button-accent: #{$palette-base-100};
      }
    }

    &.is-bordered {
      --button-border-accent: #{$palette-base-800};

      &.is-current,
      &.is-active,
      &:hover {
        --button-accent: #{$palette-base-100};
      }

      @include media-breakpoint-down(md) {
        &:hover {
          --button-accent: #{$palette-base-100};
        }
      }
    }

    &.is-disabled {
      //
    }
  }

  &--icon-left {
    svg,
    .c-icon {
      margin-right: var(--button-icon-gutter-x);
    }
  }

  &--icon-right {
    svg,
    .c-icon {
      margin-left: var(--button-icon-gutter-x);
    }
  }

  &--block {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  // Children
  // -------------------------------------------

  &__content {
    display: flex;
    align-items: center;
  }

  // Child Nodes
  // -------------------------------------------

  svg {
    height: 0.8em;
    width: auto;

    @include media-breakpoint-up(md) {
      height: 0.65em;
    }
  }
}
