// ======================================================
// Anchor
// ======================================================

.c-anchor {
  border-bottom: 1px solid;
  padding-bottom: 0.05em;

  @include media-breakpoint-up(md) {
    border-bottom-width: 2px;
  }
}
