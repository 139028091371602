// ======================================================
// Form Field
// ======================================================

:root {
  --form-field-accent: #{$palette-base-100};
  --form-field-color: #{$palette-base-800};
  --form-field-padding: 1em 1.25em;
  --form-field-placeholder-color: #{$palette-base-600};
  --form-field-border-accent: #{$palette-base-900};
  --form-field-border-width: #{px-to-rem(2)};
  --form-field-focus-accent: #{$palette-base-300};
}

.c-form-field {
  $form-field-height: px-to-rem(44);

  @include focus(var(--form-field-focus-accent));
  @include transition;

  background: var(--form-field-accent);
  color: var(--form-field-color);
  border: var(--form-field-border-width) solid var(--form-field-border-accent);
  padding: var(--form-field-padding);
  border-radius: px-to-rem(5);
  display: inline-block;
  width: 100%;
  background-clip: border-box;
  appearance: none;
  text-align: left;
  white-space: nowrap;
  transition-property: box-shadow;
  min-height: $form-field-height;

  // Un-style the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--form-field-placeholder-color);
    opacity: 1; // Override Firefox's unusual default opacity;
  }

  // States
  // -------------------------------------------

  &.is-disabled,
  &:disabled,
  &[readonly] {
    --form-field-accent: #{$palette-base-300};

    // iOS fix for unreadable disabled content;
    opacity: 1;
    cursor: default;

    &:focus {
      box-shadow: none;
    }
  }

  &.is-invalid {
    --form-field-border-accent: #{$palette-error-400};
    --form-field-focus-accent: #{$palette-error-200};
  }
}
