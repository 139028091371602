// ======================================================
// Item Card
// ======================================================

:root {
  --item-card-accent: #{$palette-base-200};
  --item-card-color: #{$palette-base-900};
  --item-card-padding: #{px-to-rem(8)};
  --item-card-border-radius: #{px-to-rem(8)};
  --item-card-gutter-x: #{px-to-rem(10)};
  --item-card-gutter-y: #{px-to-rem(10)};

  --item-card-media-width: #{px-to-rem(100)};
  --item-card-media-border-radius: #{px-to-rem(6)};
}

.c-item-card {
  background: var(--item-card-accent);
  color: var(--item-card-color);
  padding: var(--item-card-padding);
  border-radius: var(--item-card-border-radius);
  text-align: left;

  // Children
  // -------------------------------------------

  &__inner {
    display: flex;
    align-items: center;
  }

  &__media {
    margin: 0;
    flex: 0 0 auto;
    width: var(--item-card-media-width);
    border-radius: var(--item-card-media-border-radius);
    overflow: hidden;

    &--base {
      background: #{$palette-base-100};
    }

    &--primary {
      background: #{$palette-brand-primary};
    }

    &--secondary {
      background: #{$palette-brand-secondary};
    }

    &--tertiary {
      background: #{$palette-brand-tertiary};
    }
  }

  &__content {
    min-width: 0;
    margin: 0 0 0 var(--item-card-gutter-x);
    flex: 1;

    > * + * {
      margin-top: 0;
    }
  }

  &__actions {
    margin: 0 0 0 var(--item-card-gutter-x);
    flex: 0 0 auto;
  }

  &__action {
    @include strip-button-style;

    cursor: pointer;

    & + & {
      margin: 0 0 0 px-to-rem(16);
    }
  }

  &__form {
    margin: var(--item-card-gutter-y) 0 0;
  }
}
