*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

p {
  margin: 0;
}

html {
  font-family: $font-family-book;
  font-size: initial;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

.c-body {
  @include font('body');

  width: 100%;
  background-color: $background-color-root;
  color: $text-color-root;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;

  /**
   * What is this? Does it make you scared and confused?
   * This is a pattern called the "Lobotomised Owl" and it's
   * a great way to deal with margins in layouts.
   * It's my preferred way of handling spacing, but I completely
   * understand if you hate it. Feel free to remove it, and
   * do what you prefer.
   *
   * You can read more about it here: https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/
   */
  * + * {
    margin-top: 1.5em;
  }
}

main {
  &:focus {
    outline: none;
  }
}

a {
  background-color: transparent;
  color: currentColor;
}

textarea {
  overflow: auto;
  resize: vertical;
}

hr {
  border: 0;
  border-top: 1px solid $palette-base-600;
}
