// ======================================================
// Item List Form
// ======================================================

:root {
  --item-list-form-container-accent: #{$palette-base-100};
  --item-list-form-container-color: #{$palette-base-800};
  --item-list-form-container-padding: #{px-to-rem(30)} #{px-to-rem(25)} #{px-to-rem(40)};
}

.l-item-list-form {
  // Containers
  // -------------------------------------------

  &__container {
    background: var(--item-list-form-container-accent);
    color: var(--item-list-form-container-color);
    padding: var(--item-list-form-container-padding);
  }

  // Children
  // -------------------------------------------

  &__header {
    display: flex;
    justify-content: space-between;

    > * + * {
      margin-top: 0;
    }
  }

  &__content {
    margin-top: px-to-rem(35);
    text-align: left;
  }
}
