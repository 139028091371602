// ======================================================
// Image
// ======================================================

$image-ratios: (
  '3by2': (
    'x': 3,
    'y': 2,
  ),
  '5by3': (
    'x': 5,
    'y': 3,
  ),
  '14by9': (
    'x': 14,
    'y': 9,
  ),
);

$image-sizes: ();

.c-image {
  $this: &;

  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  appearance: none;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: image-aspect-ratio(1, 1); // Equal ratio
  }

  // Modifiers
  // -------------------------------------------

  // Generate ratio classes based on a map.
  @each $key, $val in $image-ratios {
    &--#{$key} {
      &::before {
        padding-top: image-aspect-ratio(map_get($val, 'x'), map_get($val, 'y'));
      }

      // For each breakpoint add a media utility
      @each $breakpoint-key, $breakpoint-val in $grid-breakpoints {
        &-#{$breakpoint-key} {
          &::before {
            @include media-breakpoint-up($breakpoint-key) {
              padding-top: image-aspect-ratio(map_get($val, 'x'), map_get($val, 'y'));
            }
          }
        }
      }
    }
  }

  // Child Nodes
  // -------------------------------------------

  // The img node
  &__item,
  > img {
    @include image-responsive;

    margin: 0;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    bottom: 0;
    transform: translateY(-50%);

    &.is-blurred {
      @include transition;

      filter: blur(5px);

      &.is-loaded {
        filter: blur(0);
      }
    }
  }

  // States
  // -------------------------------------------

  &.is-lazy-load {
    img:not([src]) {
      visibility: hidden;
    }
  }
}
