// ======================================================
// Box
// ======================================================

.c-box {
  padding: px-to-rem(16);
  background: $palette-primary-500;
  color: $palette-primary-100;
  border-radius: px-to-rem(25);

  @include media-breakpoint-up(md) {
    padding: px-to-rem(24) px-to-rem(44);
  }

  // Modifiers
  // -------------------------------------------

  &--small {
    padding: px-to-rem(24) px-to-rem(16) px-to-rem(18);
  }
}
