// ======================================================
// Item Page
// ======================================================

:root {
  --item-page-accent: #{$palette-base-300};
  --item-page-color: #{$palette-base-800};
  --item-page-media-width: #{px-to-rem(210)};

  --item-page-nav-padding: 0;

  --item-page-content-accent: transparent;
  --item-page-content-color: #{$palette-base-800};

  @include media-breakpoint-up(md) {
    --item-page-media-width: #{px-to-rem(400)};
  }
}

.l-item-page {
  $this: &;

  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--item-page-accent);
  color: var(--item-page-color);
  transition: 200ms ease all;

  // Modifiers
  // -------------------------------------------

  &--fixed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    min-height: 0;

    @include media-breakpoint-up(lg) {
      position: relative;
      min-height: 100vh;
    }
  }

  &--primary {
    --item-page-accent: #{$palette-brand-primary};
    --item-page-color: #{$palette-base-900};
  }

  &--secondary {
    --item-page-accent: #{$palette-brand-secondary};
    --item-page-color: #{$palette-base-900};
  }

  &--tertiary {
    --item-page-accent: #{$palette-brand-tertiary};
    --item-page-color: #{$palette-base-900};
  }

  // Animation
  // -------------------------------------------

  &--animation {
    &-enter,
    &-appear {
      #{$this}__title,
      #{$this}__media,
      #{$this}__sub-title {
        opacity: 0;
      }

      //#{$this}__footer-header {
      //  opacity: 0;
      //  transform: translateY(10%);
      //}

      &-active {
        #{$this}__title,
        #{$this}__media,
        #{$this}__sub-title {
          opacity: 1;
          transition: all 800ms ease;
          transition-delay: 100ms;
        }

        //#{$this}__footer-header {
        //  opacity: 1;
        //  transition: all 600ms ease;
        //  transition-delay: 200ms;
        //  transform: translateY(0);
        //}
      }

      &-done {
        #{$this}__title,
        #{$this}__media,
        #{$this}__sub-title {
          opacity: 1;
        }

        //#{$this}__footer-header {
        //  opacity: 1;
        //  transform: translateX(0);
        //}
      }
    }
  }

  // Children
  // -------------------------------------------

  &__header {
    flex: 0 0 auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(md) {
      padding: px-to-rem(32) px-to-rem(44) 0;
    }
  }

  &__indicator {
    margin: 0;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  &__nav {
    margin: 0;
    width: 100%;
    padding: var(--item-page-nav-padding);

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    > * + * {
      margin-top: 0;
    }
  }

  &__title {
    display: none;
    flex: 0 0 auto;
    margin: 0;
    padding: 0 px-to-rem(24) px-to-rem(24);

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__sub-title {
    flex: 0 0 auto;
    margin: 0;
    padding: 0 px-to-rem(24) px-to-rem(44);

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__media {
    flex: 1 1 auto;
    margin: px-to-rem(44) 0 0;
    position: relative;
    min-height: px-to-rem(100);
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  &__media-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    height: 100%;
    max-height: px-to-rem(140);
    width: auto;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      max-height: px-to-rem(300);
    }
  }

  &__footer {
    min-height: 50vh;
    flex: 0 0 auto;
    background: #{$palette-base-100};
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: px-to-rem(30) px-to-rem(30) px-to-rem(25);

    @include media-breakpoint-up(md) {
      background: transparent;
      min-height: 0;
      padding: px-to-rem(30) px-to-rem(44) px-to-rem(44);
    }

    @include media-breakpoint-up(xxxl) {
      padding: px-to-rem(30) px-to-rem(44) px-to-rem(70);
    }
  }

  &__footer-header {
    //
  }

  &__footer-content {
    @include media-breakpoint-up(md) {
      margin-top: px-to-rem(32);
    }

    @include media-breakpoint-up(xxxl) {
      margin-top: px-to-rem(120);
    }
  }

  &__footer-footer {
    //
  }
}
