// ======================================================
// Splash Page
// ======================================================

:root {
  --splash-page-accent: #{$palette-brand-secondary};
  --splash-page-color: #{$palette-base-900};
  --splash-page-content-padding: #{px-to-rem(30)} 0;
  --splash-page-footer-padding: 0 0 #{px-to-rem(90)};

  --splash-page-state-logo-width: #{px-to-rem(110)};

  --splash-page-logo-width: 50vw;

  --spash-page-svg-width: #{px-to-rem(100)};
  --spash-page-svg-height: auto;

  @include media-breakpoint-up(md) {
    --splash-page-footer-padding: 0;
    --splash-page-logo-width: 25vw;

    --splash-page-state-logo-width: #{px-to-rem(205)};
  }
}

.l-splash-page {
  $this: &;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--splash-page-accent);
  color: var(--splash-page-color);
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  // Modifiers
  // -------------------------------------------

  &--scrollable {
    position: relative;
    min-height: 100vh;

    @include media-breakpoint-up(md) {
      position: relative;
      height: 100%;
      min-height: 100vh;
    }

    #{$this}__content-header {
      position: static;
    }

    #{$this}__content {
      position: relative;

      @include media-breakpoint-up(md) {
        justify-content: flex-start;
        position: relative;
        height: 100%;
        min-height: 100vh;
      }
    }

    #{$this}__content-footer {
      @include media-breakpoint-up(md) {
        margin: px-to-rem(24) 0 0;
      }
    }
  }

  // Children
  // -------------------------------------------

  &__content {
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__content-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    flex: 0 0 auto;
    margin: px-to-rem(20) 0 0;

    @include media-breakpoint-up(md) {
      position: static;
    }

    @include media-breakpoint-up(xl) {
      margin: px-to-rem(50) 0 0;
    }
  }

  &__content-content {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @include media-breakpoint-up(md) {
      flex: 1 1 auto;
      margin: px-to-rem(80) 0 0;
    }
  }

  &__content-footer {
    margin: px-to-rem(24) 0 0;
    flex: 0 0 auto;
    padding: 0 0 px-to-rem(20);

    @include media-breakpoint-up(md) {
      margin: px-to-rem(80) 0 0;
      padding: 0 0 px-to-rem(50);
    }
  }

  &__state-logo {
    width: var(--splash-page-state-logo-width);
    height: auto;
  }

  &__logo,
  &__logo-alt {
    flex: 1 1 auto;
    height: 100%;
  }

  &__logo {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
  }

  &__logo-item,
  &__logo-alt-item {
    display: block;
    height: 100%;
    width: auto;
  }

  &__logo-item {
    width: px-to-rem(165);

    @include media-breakpoint-up(md) {
      max-height: px-to-rem(300);
      width: auto;
      margin: 0 auto;
    }
  }

  &__logo-alt-item {
    width: px-to-rem(313);

    @include media-breakpoint-up(md) {
      width: px-to-rem(577);
    }
  }

  &__canvas {
    margin: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
  }

  &__canvas-item {
    position: absolute;
    margin: 0;

    svg {
      width: var(--spash-page-svg-width);
      height: var(--spash-page-svg-height);
      display: block;
    }

    // Ring
    &--primary {
      --spash-page-svg-width: 10vw;

      display: none;
      left: 25%;
      bottom: 0;
      transform: translate3d(-75%, 23%, 0);

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    // Laptop
    &--secondary {
      --spash-page-svg-width: 40vw;

      bottom: 0;
      left: 15%;
      transform: translate(0, 10%) rotate(15deg);

      @include media-breakpoint-up(md) {
        --spash-page-svg-width: 17.5vw;
        bottom: unset;
        left: unset;
        top: 0;
        right: 10%;
        transform: rotate(15deg) translate(-10%, -10%);
      }
    }

    // Phone
    &--tertiary {
      --spash-page-svg-width: 22.5vw;

      bottom: 25%;
      right: 0;
      transform: translate3d(20%, 45%, 0) rotate(15deg);

      @include media-breakpoint-up(md) {
        --spash-page-svg-width: 10vw;
        bottom: 0;
        right: 15%;
        transform: rotate(15deg) translateY(5%);
      }
    }

    // Washing Machine
    &--quinary {
      --spash-page-svg-width: 30vw;

      top: 25%;
      left: 0;
      transform: translate3d(-15%, -40%, 0) rotate(40deg);

      @include media-breakpoint-up(md) {
        --spash-page-svg-width: 15vw;
        top: 50%;
        transform: translate3d(-15%, -40%, 0) rotate(40deg);
      }
    }

    // Couch
    &--senary {
      --spash-page-svg-width: 40vw;

      top: 0;
      right: 0;
      transform: rotate(-90deg) translateY(11%);

      @include media-breakpoint-up(md) {
        --spash-page-svg-width: 17.5vw;
        top: 50%;
        transform: rotate(-90deg) translate(70%, 11%);
      }
    }

    // Chair
    &--septenary {
      --spash-page-svg-width: 15vw;

      display: none;
      top: 0;
      left: 15%;
      transform: rotate(180deg);

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
}
