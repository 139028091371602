// ======================================================
// Typography
// ======================================================

$typography-keys: ('large', 'body', 'small');

.c-typography {
  display: block;

  // Modifiers
  // -------------------------------------------

  // Loop through the themes, and style each modifier.
  @each $key, $val in $typography-keys {
    &--#{$key} {
      @include font($key);
    }
  }

  // Child Nodes
  // -------------------------------------------

  > a {
    text-decoration: none;
  }
}
