// ======================================================
// Variables
// ======================================================

// Colors
// -------------------------------------------

$palette-brand-primary: #3f9ed8;
$palette-brand-secondary: #f595a6;
$palette-brand-tertiary: #f48a3a;
$palette-brand-quaternary: #fff200;

$palette-base-100: #ffffff;
$palette-base-200: #f7f7f7;
$palette-base-300: #edf2f7;
$palette-base-400: #e2e8f0;
$palette-base-500: #cbd5e0;
$palette-base-600: #a0aec0;
$palette-base-700: #3b3b3b;
$palette-base-800: #242c2e;
$palette-base-900: #000;

$palette-primary-100: #fff;
$palette-primary-200: #80a5c9;
$palette-primary-300: #4d81b3;
$palette-primary-400: #2666a3;
$palette-primary-500: #004b93;
$palette-primary-600: #00448b;
$palette-primary-700: #003b80;
$palette-primary-800: #003376;
$palette-primary-900: #002364;

$palette-secondary-100: #fff;
$palette-secondary-200: #facad3;
$palette-secondary-300: #f8b5c1;
$palette-secondary-400: #f7a5b3;
$palette-secondary-500: #f595a6;
$palette-secondary-600: #f48d9e;
$palette-secondary-700: #f28295;
$palette-secondary-800: #f0788b;
$palette-secondary-900: #ee677b;

$palette-tertiary-100: #fff;
$palette-tertiary-200: #fff980;
$palette-tertiary-300: #fff64d;
$palette-tertiary-400: #fff426;
$palette-tertiary-500: #fff200;
$palette-tertiary-600: #fff000;
$palette-tertiary-700: #ffee00;
$palette-tertiary-800: #ffec00;
$palette-tertiary-900: #ffe800;

$palette-info-100: #ebf8ff;
$palette-info-200: #bee3f8;
$palette-info-300: #90cdf4;
$palette-info-400: #63b3ed;
$palette-info-500: #4299e1;
$palette-info-600: #3182ce;
$palette-info-700: #2b6cb0;
$palette-info-800: #2c5282;
$palette-info-900: #2a4365;

$palette-warning-100: #fffff0;
$palette-warning-200: #fefcbf;
$palette-warning-300: #faf089;
$palette-warning-400: #f6e05e;
$palette-warning-500: #ecc94b;
$palette-warning-600: #d69e2e;
$palette-warning-700: #b7791f;
$palette-warning-800: #975a16;
$palette-warning-900: #744210;

$palette-error-100: #fff5f5;
$palette-error-200: #fed7d7;
$palette-error-300: #feb2b2;
$palette-error-400: #fc8181;
$palette-error-500: #f56565;
$palette-error-600: #e53e3e;
$palette-error-700: #c53030;
$palette-error-800: #9b2c2c;
$palette-error-900: #742a2a;

$palette-success-100: #f0fff4;
$palette-success-200: #c6f6d5;
$palette-success-300: #9ae6b4;
$palette-success-400: #68d391;
$palette-success-500: #48bb78;
$palette-success-600: #38a169;
$palette-success-700: #2f855a;
$palette-success-800: #276749;
$palette-success-900: #22543d;

$palette-map: (
  'base': (
    '100': $palette-base-100,
    '200': $palette-base-200,
    '300': $palette-base-300,
    '400': $palette-base-400,
    '500': $palette-base-500,
    '600': $palette-base-600,
    '700': $palette-base-700,
    '800': $palette-base-800,
    '900': $palette-base-900,
  ),
  'primary': (
    '100': $palette-primary-100,
    '200': $palette-primary-200,
    '300': $palette-primary-300,
    '400': $palette-primary-400,
    '500': $palette-primary-500,
    '600': $palette-primary-600,
    '700': $palette-primary-700,
    '800': $palette-primary-800,
    '900': $palette-primary-900,
  ),
  'secondary': (
    '100': $palette-secondary-100,
    '200': $palette-secondary-200,
    '300': $palette-secondary-300,
    '400': $palette-secondary-400,
    '500': $palette-secondary-500,
    '600': $palette-secondary-600,
    '700': $palette-secondary-700,
    '800': $palette-secondary-800,
    '900': $palette-secondary-900,
  ),
);

// HTML / Body
// -------------------------------------------

$background-color-root: $palette-base-200;
$text-color-root: $palette-base-800;

// Forms
// -------------------------------------------

$focus-color: rgba($palette-base-600, 0.25);

// Grid
// -------------------------------------------

$grid-gutter-width: px-to-rem(20);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

$container-max-widths: (
  sm: px-to-rem(540),
  md: px-to-rem(720),
  lg: px-to-rem(960),
  xl: px-to-rem(1200),
  xxl: px-to-rem(1200),
  xxxl: px-to-rem(1200),
);

// Sizes
// -------------------------------------------

// Fibonacci if possible.
$sizes: (
  'auto': auto,
  '0': 0,
  'xs': px-to-rem(10),
  'sm': px-to-rem(20),
  'md': px-to-rem(30),
  'lg': px-to-rem(50),
  'xl': px-to-rem(80),
  'xxl': px-to-rem(130),
);

// Animation
// -------------------------------------------

$transition-base: all 200ms ease-in-out;

// Fonts
// -------------------------------------------

@font-face {
  font-family: 'Circular-Std-Black';
  src: url('../fonts/CircularStd-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Std-Bold';
  src: url('../fonts/CircularStd-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'circular-std-book';
  src: url('../fonts/CircularStd-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$font-family-book: 'circular-std-book', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-bold: 'circular-std-bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-black: 'circular-std-black', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$font-sizes: (
  'h0': (
    'font-size': (
      'xs': 2rem,
      'lg': 2.5rem,
    ),
    'font-weight': (
      'xs': normal,
    ),
    'font-family': $font-family-book,
    'letter-spacing': normal,
    'line-height': normal,
    'text-transform': none,
  ),
  'h1': (
    'font-size': (
      'xs': 2rem,
    ),
    'font-weight': (
      'xs': normal,
    ),
    'font-family': $font-family-book,
    'letter-spacing': normal,
    'line-height': normal,
    'text-transform': none,
  ),
  'h2': (
    'font-size': (
      'xs': px-to-rem(25),
      'lg': px-to-rem(40),
    ),
    'font-weight': (
      'xs': normal,
    ),
    'font-family': $font-family-book,
    'letter-spacing': normal,
    'line-height': normal,
    'text-transform': none,
  ),
  'h3': (
    'font-size': (
      'xs': px-to-rem(20),
      'lg': px-to-rem(30),
    ),
    'font-weight': (
      'xs': normal,
    ),
    'font-family': $font-family-book,
    'letter-spacing': normal,
    'line-height': normal,
    'text-transform': none,
  ),
  'h4': (
    'font-size': (
      'xs': px-to-rem(19),
    ),
    'font-weight': (
      'xs': normal,
    ),
    'font-family': $font-family-bold,
    'letter-spacing': normal,
    'line-height': normal,
    'text-transform': none,
  ),
  'large': (
    'font-size': (
      'xs': 1.25rem,
    ),
    'font-weight': (
      'xs': normal,
    ),
    'font-family': $font-family-book,
    'letter-spacing': normal,
    'line-height': normal,
    'text-transform': none,
  ),
  'body': (
    'font-size': (
      'xs': px-to-rem(14),
      'lg': px-to-rem(18),
    ),
    'font-weight': (
      'xs': normal,
    ),
    'font-family': $font-family-book,
    'letter-spacing': normal,
    'line-height': 1.5,
    'text-transform': none,
  ),
  'small': (
    'font-size': (
      'xs': px-to-rem(12),
    ),
    'font-weight': (
      'xs': normal,
    ),
    'font-family': $font-family-book,
    'letter-spacing': normal,
    'line-height': normal,
    'text-transform': none,
  ),
);

// Z Indices
// -------------------------------------------

$z-index-popover: 1000;
$z-index-sticky: 1010;
$z-index-fixed: 1020;
$z-index-modal-backdrop: 1030;
$z-index-modal: 1040;
$z-index-mobile-nav: 1050;
$z-index-mobile-action: 1060;
