// ======================================================
// Tooltip
// ======================================================

:root {
  --tooltip-accent: #{$palette-base-100};
  --tooltip-color: #{$palette-base-800};
  --tooltip-border-radius: 80px;
  --tooltip-padding: 10px 30px;
  --tooltip-box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  --tooltip-arrow-size: 8px;
}

.c-tooltip {
  position: relative;
  display: block;
  margin-top: 0;

  // Elements
  // -------------------------------------------

  &__popup {
    pointer-events: none;
    position: absolute;
    display: inline-block;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    background: var(--tooltip-accent) none;
    border-radius: var(--tooltip-border-radius);
    box-shadow: var(--tooltip-box-shadow);

    &__arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-right-color: transparent;
      border-style: solid;
      top: auto;
      bottom: calc(-1 * var(--tooltip-arrow-size));
      left: 50%;
      margin-left: calc(-1 * var(--tooltip-arrow-size));
      border-width: var(--tooltip-arrow-size) var(--tooltip-arrow-size) 0;
      border-top-color: var(--tooltip-accent);
    }

    &__label {
      color: var(--tooltip-color);
      padding: var(--tooltip-padding);
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
    }
  }
}
