// ======================================================
// Button Group
// ======================================================

:root {
  --button-group-gap: #{px-to-rem(10)};
  --button-group-padding: 0;
}

.l-button-group {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: var(--button-group-gap);
  align-items: center;
  padding: var(--button-group-padding);
  max-width: px-to-rem(725);
  margin-right: auto;
  margin-left: auto;

  // Modifiers
  // -------------------------------------------

  &--3-column {
    --button-group-padding: 0 #{px-to-rem(10)};

    grid-template-columns: auto minmax(0, 1fr) auto;
    align-items: center;

    @include media-breakpoint-up(md) {
      --button-group-padding: 0;

      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  // Child Nodes
  // -------------------------------------------

  > * + * {
    margin-top: 0;
  }
}
