// ======================================================
// Content
// ======================================================

:root {
  --content-width: #{px-to-rem(620)};
}

.c-content {
  margin-right: auto;
  margin-left: auto;
  padding: 0 px-to-rem(36);
  max-width: var(--content-width);

  @include media-breakpoint-up(md) {
    padding: 0;
  }
}
