// ======================================================
// Indicator
// ======================================================

:root {
  --indicator-item-height: #{px-to-rem(9)};
  --indicator-item-border-radius: 0;
  --indicator-item-gutter: 0;
  --indicator-item-accent: #{$palette-base-300};
  --indicator-item-active-accent: #{$palette-primary-500};
}

.c-indicator {
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;

  // Modifiers
  // -------------------------------------------

  &--primary {
    --indicator-item-accent: #{rgba($palette-primary-500, 0.2)};
    --indicator-item-active-accent: #{$palette-primary-500};
  }

  &--secondary {
    --indicator-item-accent: #{rgba($palette-secondary-500, 0.2)};
    --indicator-item-active-accent: #{$palette-secondary-500};
  }

  // Children
  // -------------------------------------------

  &__item {
    margin: 0;
    flex: 1;
    height: var(--indicator-item-height);
    border-radius: var(--indicator-item-border-radius);
    background: var(--indicator-item-accent);

    &:before {
      content: ' ';
      display: block;
      height: var(--indicator-item-height);
      width: 0;
      transition: width 0.5s ease;
      background: var(--indicator-item-active-accent);
    }

    & + & {
      margin-left: var(--indicator-item-gutter);
    }

    &.is-active {
      &:before {
        width: 100%;
      }
    }
  }
}
