// ======================================================
// Modal
// ======================================================

.c-modal {
  position: absolute;
  top: $grid-gutter-width;
  left: $grid-gutter-width;
  right: $grid-gutter-width;
  bottom: $grid-gutter-width;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  outline: none;

  // Children
  // -------------------------------------------

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: $z-index-modal-backdrop;
  }

  &__body {
    max-width: px-to-rem(505);
    margin: 0 auto;
  }
}

// Third Party
// -------------------------------------------

.ReactModalPortal {
  margin: 0;
}
