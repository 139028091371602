// ======================================================
// Display
// ======================================================

.u-d--none {
  display: none !important;
}

.u-d--md-block {
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.u-d--lg-block {
  @include media-breakpoint-up(lg) {
    display: block !important;
  }
}

.u-d--lg-flex {
  @include media-breakpoint-up(lg) {
    display: flex !important;
  }
}

.u-d--md-none {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.u-d--lg-none {
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}
