// ======================================================
// Alert Box
// ======================================================

:root {
  --alert-box-background-color: #{$palette-base-400};
  --alert-box-font-size: #{px-to-rem(14)};
}

.c-alert-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--alert-box-background-color);
  z-index: $z-index-modal;

  &__content {
    padding: 10px 35px 10px 10px;
    font-size: var(--alert-box-font-size);
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: px-to-rem(30);
    border: 0;
    line-height: 0.5;
    height: 35px;
    width: 35px;
    background: transparent;
    padding: 0;
    outline: none;
    cursor: pointer;
  }

  .c-text-button {
    margin: 0 5px;
    font-size: inherit;
    color: inherit;
    height: auto;
  }
}
