// ==================================================
// Background
// ==================================================

@each $paletteKey, $colorsMap in $palette-map {
  @each $colorKey, $color in $colorsMap {
    .u-background--#{$paletteKey}-#{$colorKey} {
      background-color: $color !important;
    }
  }
}
