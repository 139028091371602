// ======================================================
// Highlight
// ======================================================

:root {
  --highlight-accent: #{$palette-brand-quaternary};
  --highlight-color: #{$palette-base-900};
}

.c-highlight {
  $this: &;

  display: inline-block;

  // Modifiers
  // -------------------------------------------

  &--medium {
    font-size: 1.25rem;

    #{$this}__content {
      word-break: break-word;
    }
  }

  &--large {
    font-size: px-to-rem(55);
    font-family: $font-family-black;
  }

  // Children
  // -------------------------------------------

  &__content {
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    display: inline-block;
    background: var(--highlight-accent);
    color: var(--highlight-color);
    padding: 0 0.25em;
    word-break: break-all;
  }
}
