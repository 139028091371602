// ======================================================
// Typography
// ======================================================

$typography-keys: ('h1', 'h2', 'h3', 'h4');

.c-title {
  display: block;

  // Modifiers
  // -------------------------------------------

  // Loop through the themes, and style each modifier.
  @each $key, $val in $typography-keys {
    &--#{$key} {
      @include font($key);
    }
  }

  &--truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Child Nodes
  // -------------------------------------------

  > a {
    text-decoration: none;
  }
}
