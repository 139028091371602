// ======================================================
// Spacing
// ======================================================

$border-sizes: (
  '0': 0,
);

$classes: ('bt', 'br', 'bb', 'bl');

/**
 * @desc Assign a margin based on a key and value.
 * @param $direction string
 * @param $value string
 */
@mixin spacing($direction, $value) {
  @if $direction == 'bt' {
    border-top-width: $value !important;
  } @else if $direction == 'br' {
    border-right-width: $value !important;
  } @else if $direction == 'bb' {
    border-bottom-width: $value !important;
  } @else if $direction == 'bl' {
    border-left-width: $value !important;
  }
}

// Generate spacing classes.
@each $class in $classes {
  @each $key, $val in $border-sizes {
    .u-#{$class}--#{$key} {
      @include spacing($class, $val);
    }

    @each $breakpointKey, $breakpointVal in $grid-breakpoints {
      .u-#{$class}--#{$breakpointKey}-#{$key} {
        @include media-breakpoint-up($breakpointKey) {
          @include spacing($class, $val);
        }
      }
    }
  }
}
