// ======================================================
// Container
// ======================================================

@mixin make-container() {
  width: 100%;
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(lg) {
    padding-right: calc($grid-gutter-width / 2);
    padding-left: calc($grid-gutter-width / 2);
  }
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}
