// ======================================================
// Focus
// ======================================================

@mixin focus($color: $focus-color) {
  &:focus {
    outline: none;
    box-shadow: 0 0 0 px-to-rem(3) $color;
  }
}
