// ======================================================
// Range Slider
// ======================================================

:root {
  --range-slider-border-radius: #{px-to-rem(5)};

  // Fill
  --range-slider-fill-accent: #{$palette-primary-500};
  --range-slider-fill-active-accent: #{$palette-primary-500};
  --range-slider-fill-border-radius: var(--range-slider-border-radius);

  // Handle
  --range-slider-handle-accent: #{$palette-primary-500};
  --range-slider-handle-border-accent: #{$palette-primary-500};
  --range-slider-handle-border-width: 0;
  --range-slider-handle-border-radius: 50%;

  // Handle inner
  --range-slider-handle-inner-size: #{px-to-rem(7)};
  --range-slider-handle-inner-accent: #{$palette-base-100};

  // Label
  --range-slider-label-font-size: #{px-to-rem(20)};
  --range-slider-label-value-font-size: #{px-to-rem(27)};

  @include media-breakpoint-up(md) {
    --range-slider-fill-accent: #{$palette-base-800};
    --range-slider-fill-active-accent: #{$palette-base-800};

    --range-slider-handle-accent: #{$palette-base-800};
    --range-slider-handle-border-accent: #{$palette-base-800};

    --range-slider-border-radius: #{px-to-rem(9)};
    --range-slider-handle-inner-size: #{px-to-rem(14)};

    --range-slider-label-font-size: #{px-to-rem(27)};
    --range-slider-label-value-font-size: #{px-to-rem(38)};
  }
}

$range-slider-height: px-to-rem(8);
$range-slider-handle-size: px-to-rem(36);

$range-slider-height-lg: px-to-rem(14);
$range-slider-handle-size-lg: px-to-rem(80);

.c-range-slider {
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  width: 100%;
  max-width: px-to-rem(725);

  // Children
  // -------------------------------------------

  &__header {
    text-align: center;
  }

  &__content {
    margin-top: 0;
  }

  &__labels {
    position: relative;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
  }

  &__label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    margin: 0;
    font-size: var(--range-slider-label-font-size);

    &:last-child {
      right: 0;
      left: auto;
    }

    &--value {
      position: static;
      transform: none;
      width: 100%;
      text-align: center;
      padding-bottom: px-to-rem(5);
      font-size: var(--range-slider-label-value-font-size);
      font-weight: bold;
    }
  }
}

/**
 * Third party DOM node.
 */
.rangeslider {
  margin: 0;
  position: relative;
  background-color: var(--range-slider-fill-accent);
  touch-action: none;
  height: $range-slider-height;
  border-radius: var(--range-slider-border-radius);

  @include media-breakpoint-up(md) {
    height: $range-slider-height-lg;
  }

  // Children
  // -------------------------------------------

  &__container {
    padding: (calc($range-slider-handle-size / 2) - calc($range-slider-height / 2)) 0;

    @include media-breakpoint-up(md) {
      padding: (calc($range-slider-handle-size-lg / 2) - calc($range-slider-height-lg / 2)) 0;
    }
  }

  &__fill {
    display: block;
    height: 100%;
    background-color: var(--range-slider-fill-active-accent);
    border-radius: var(--range-slider-fill-border-radius);
    top: 0;
  }

  &__handle {
    margin: 0;
    background: var(--range-slider-handle-accent);
    cursor: pointer;
    display: inline-block;
    position: absolute;
    border: var(--range-slider-handle-border-width) solid var(--range-slider-handle-border-accent);
    width: $range-slider-handle-size;
    height: $range-slider-handle-size;
    border-radius: var(--range-slider-handle-border-radius);
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    @include media-breakpoint-up(md) {
      width: $range-slider-handle-size-lg;
      height: $range-slider-handle-size-lg;
    }

    &:focus {
      outline: none;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: var(--range-slider-handle-inner-size);
      height: var(--range-slider-handle-inner-size);
      background: var(--range-slider-handle-inner-accent);
      border-radius: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}
