// ==================================================
// Color
// ==================================================

@each $paletteKey, $colorsMap in $palette-map {
  @each $colorKey, $color in $colorsMap {
    .u-color--#{$paletteKey}-#{$colorKey} {
      color: $color !important;
    }
  }
}
