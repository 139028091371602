// CSSTransitionGroup Animations
// -------------------------------------------

.u-animation--fade-up-down {
  $length: 0;
  $animation-speed: 250ms;
  &-appear {
    opacity: 0;
    transform: translate3d(0, 100%, 0);

    &-active {
      opacity: 1;
      transform: translate3d(0, $length, 0);
      transition: transform $animation-speed ease, opacity $animation-speed ease;
    }

    &-done {
      opacity: 1;
      transform: translate3d(0, $length, 0);
    }
  }

  &-enter {
    opacity: 0;
    transform: translate3d(0, 100%, 0);

    &-active {
      opacity: 1;
      transform: translate3d(0, $length, 0);
      transition: transform $animation-speed ease, opacity $animation-speed ease;
    }

    &-done {
      opacity: 1;
      transform: translate3d(0, $length, 0);
    }
  }

  &-exit {
    opacity: 1;
    transform: translate3d(0, $length, 0);

    &-active {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
      transition: transform $animation-speed ease, opacity $animation-speed ease;
    }

    &-done {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  }
}

.u-animation--slide-up-down {
  $length: 0;
  $animation-speed: 250ms;
  &-appear {
    transform: translate3d(0, -100%, 0);

    &-active {
      transform: translate3d(0, $length, 0);
      transition: transform $animation-speed ease;
    }

    &-done {
      transform: translate3d(0, $length, 0);
    }
  }

  &-enter {
    transform: translate3d(0, -100%, 0);

    &-active {
      transform: translate3d(0, $length, 0);
      transition: transform $animation-speed ease;
    }

    &-done {
      transform: translate3d(0, $length, 0);
    }
  }

  &-exit {
    transform: translate3d(0, $length, 0);

    &-active {
      transform: translate3d(0, -100%, 0);
      transition: transform $animation-speed ease;
    }

    &-done {
      transform: translate3d(0, -100%, 0);
    }
  }
}

.u-animation--fade-in-out {
  $animation-speed: 300ms;
  &-appear {
    opacity: 0;

    &-active {
      opacity: 1;
      transition: opacity $animation-speed ease;
    }

    &-done {
      opacity: 1;
    }
  }

  &-enter {
    opacity: 0;

    &-active {
      opacity: 1;
      transition: opacity $animation-speed ease;
    }

    &-done {
      opacity: 1;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transition: opacity $animation-speed ease;
    }

    &-done {
      opacity: 0;
    }
  }
}
