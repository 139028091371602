// ======================================================
// Text
// ======================================================

.u-text--center {
  text-align: center;
}

.u-text--left {
  text-align: left;
}

.u-text--uppercase {
  text-transform: uppercase;
}
