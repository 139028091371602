// ======================================================
// Item List
// ======================================================

:root {
  --item-list-gutter-y: #{px-to-rem(16)};
}

.c-item-list {
  list-style: none;
  padding: 0;

  // Children
  // -------------------------------------------

  &__item {
    & + & {
      margin-top: var(--item-list-gutter-y);
    }
  }
}
